import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { StaticImage } from 'gatsby-plugin-image';
import * as styles from './reviews.module.scss';

const Reviews = ({ intl: { formatMessage } }) => {
  const comments = [
    {
      id: 1,
      text: formatMessage({ id: 'aboutUsReviewsContent2' }),
    },
    {
      id: 2,
      text: formatMessage({ id: 'aboutUsReviewsContent3' }),
    },
    {
      id: 3,
      text: formatMessage({ id: 'aboutUsReviewsContent4' }),
    },
  ];

  return (
    <section className={styles.container_reviews}>
      <div className={styles.container_reviews__content_world}>
        <StaticImage
          src="../../../images/aboutUs/world.webp"
          className={styles.container_reviews__content_world__img}
          alt="world"
          placeholder="blurred"
          loading="lazy"
          layout="constrained"
          formats={["auto", "webp", "avif"]}
        />
      </div>
      <div className={styles.container_reviews__content_review}>
        <div className={styles.container_reviews__content_review__content_btn}>
          <p className={styles.container_reviews__content_review__text_btn}>
            {formatMessage({ id: 'testimonial' })}
          </p>
        </div>
        <h2 className="title">
          {' '}
          {formatMessage({ id: 'aboutUsReviewsTitle' })}
        </h2>
        <h6 className={styles.container_reviews__content_review__subtitle}>
          {formatMessage({ id: 'aboutUsReviewsContent1' })}
        </h6>
        <div
          className={
            styles.container_reviews__content_review__container_comments
          }
        >
          {comments.map((comment) => (
            <div
              key={comment.id}
              className={
                styles.container_reviews__content_review__container_comments__card
              }
            >
              <div
                className={
                  styles.container_reviews__content_review__container_comments__card__quotes
                }
              >
                <StaticImage
                  src="../../../images/aboutUs/quote.webp"
                  alt="quote"
                  placeholder="blurred"
                  loading="lazy"
                  layout="constrained"
                  formats={["auto", "webp", "avif"]}
                />
              </div>
              <p
                className={
                  styles.container_reviews__content_review__container_comments__card__text
                }
              >
                {comment.text}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

Reviews.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(Reviews);
