// extracted by mini-css-extract-plugin
export var body_background = "#f8f9fa";
export var container_reviews = "reviews-module__container_reviews___iPe3n";
export var container_reviews__content_review = "reviews-module__container_reviews__content_review___FuIsh";
export var container_reviews__content_review__container_comments = "reviews-module__container_reviews__content_review__container_comments___QuEsq";
export var container_reviews__content_review__container_comments__card = "reviews-module__container_reviews__content_review__container_comments__card___XZqqq";
export var container_reviews__content_review__container_comments__card__quotes = "reviews-module__container_reviews__content_review__container_comments__card__quotes___IstHU";
export var container_reviews__content_review__container_comments__card__text = "reviews-module__container_reviews__content_review__container_comments__card__text___H8FnI";
export var container_reviews__content_review__container_comments__content_review = "reviews-module__container_reviews__content_review__container_comments__content_review___LJJd+";
export var container_reviews__content_review__content_btn = "reviews-module__container_reviews__content_review__content_btn___PbTta";
export var container_reviews__content_review__subtitle = "reviews-module__container_reviews__content_review__subtitle___bodiy";
export var container_reviews__content_review__text_btn = "reviews-module__container_reviews__content_review__text_btn___cM0S8";
export var container_reviews__content_world = "reviews-module__container_reviews__content_world___USdGh";
export var container_reviews__content_world__img = "reviews-module__container_reviews__content_world__img___ksYL3";
export var even_second_color = "#557da1";
export var font_family = "-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,\"Noto Sans\",sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\",\"Noto Color Emoji\"";
export var lg = "1200px";
export var md = "900px";
export var paper_background = "#fff";
export var primary = "#2ed3ae";
export var primary_light = "#e0f9f3";
export var secondary = "#868d96";
export var sm = "600px";
export var spacing = "8";
export var white = "#fff";
export var xl = "1536px";
export var xxl = "2500px";