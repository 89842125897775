import * as React from 'react';
import { useIntl } from 'react-intl';

import { Professionals, Banner, History, Culture, Reviews } from '@aboutUs';

const AboutUsPage = () => {
  return (
    <main>
      <Banner />
      <History />
      <Culture />
      <Reviews />
      <Professionals />
    </main>
  );
};

export default AboutUsPage;

export const Head = () => {
  const intl = useIntl();
  return (
    <>
      <title>{intl.formatMessage({ id: 'aboutUsSeoTitle' })}</title>
      <meta
        name="description"
        content={intl.formatMessage({ id: 'aboutUsSeoDescription' })}
      />
      <meta
        name="keywords"
        content={intl.formatMessage({ id: 'aboutUsSeoKeywords' })}
      />
      <meta name="robots" content={intl.formatMessage({ id: 'seoRobots' })} />
      <link rel="canonical" href={intl.formatMessage({ id: 'aboutUsOgUrl' })} />
      <meta
        property="og:title"
        content={intl.formatMessage({ id: 'aboutUsSeoTitle' })}
      />
      <meta
        property="og:description"
        content={intl.formatMessage({ id: 'aboutUsSeoDescription' })}
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content={intl.formatMessage({ id: 'aboutUsOgUrl' })}
      />
      <meta
        property="og:image"
        content={intl.formatMessage({ id: 'ogImage' })}
      />
      <meta
        name="twitter:card"
        content={intl.formatMessage({ id: 'twitterCard' })}
      />
      <meta
        name="twitter:title"
        content={intl.formatMessage({ id: 'aboutUsSeoTitle' })}
      />
      <meta
        name="twitter:description"
        content={intl.formatMessage({ id: 'aboutUsSeoDescription' })}
      />
      <meta
        name="twitter:image"
        content={intl.formatMessage({ id: 'twitterImage' })}
      />
    </>
  );
};
